<template>
    <b-modal
      v-model="data.showModal"
      size="md"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
    >
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          Filters
        </div>
      </div>

      <div>
        <table class="table table-google table-highlight table-filter">
          <tbody>
            <tr>
              <td>Date:</td>
              <td>
                <div>
                  <date-range
                    v-model="filters"
                    no-label
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td class="row-fit">Status:</td>
              <td>
                <div>
                  <button-select
                    v-model="filters.candle_status"
                    :options="options.candle_status"
                    size="sm"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>Favorite:</td>
              <td>
                <div>
                  <button-select
                    v-model="filters.is_favorite"
                    :options="options.is_favorite"
                    size="sm"
                  />
                </div>
              </td>
            </tr>

            <tr>
            <td>Reference:</td>
            <td>
              <b-form-input
                v-model="filters.search"
                size="sm"
                autocomplete="off"
              />
            </td>
          </tr>

            <tr>
              <td>Brand:</td>
              <td>
                <div>
                  <v-select
                    v-model="filters.brand_id"
                    :options="dropdowns.candle_brand"
                    size="sm"
                    label="text"
                    :reduce="option => option.value"
                    class="w-100"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>Glass:</td>
              <td>
                <div>
                  <v-select
                    v-model="filters.glass_id"
                    :options="dropdowns.glass"
                    size="sm"
                    label="text"
                    :reduce="option => option.value"
                    class="w-100"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>Wax:</td>
              <td>
                <div>
                  <v-select
                    v-model="filters.wax_id"
                    :options="dropdowns.wax"
                    size="sm"
                    label="text"
                    :reduce="option => option.value"
                    class="w-100"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>Wick:</td>
              <td>
                <div>
                  <v-select
                    v-model="filters.wick_id"
                    :options="dropdowns.wick"
                    size="sm"
                    label="text"
                    :reduce="option => option.value"
                    class="w-100"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>Dye:</td>
              <td>
                <div class="flex justify-between gap-4 w-100">
                  <v-select
                    v-model="filters.dye_name_id"
                    :options="dropdowns.dye"
                    size="sm"
                    label="text"
                    :reduce="option => option.value"
                    class="w-100"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>Additive:</td>
              <td>
                <div>
                  <div class="flex justify-between gap-4 w-100">
                    <v-select
                      v-model="filters.additive_name_id"
                      :options="dropdowns.additive"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      class="w-100"
                    />
                    <v-select
                      v-model="filters.additive_pct_id"
                      :options="dropdowns.additive_pct"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      class="w-1/3"
                    />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>Fragrance:</td>
              <td>
                <div class="flex justify-between gap-4 w-100">
                  <v-select
                    v-model="filters.fragrance_name_id"
                    :options="dropdowns.fragrance"
                    size="sm"
                    label="text"
                    :reduce="option => option.value"
                    class="w-100"
                  />
                  <v-select
                    v-model="filters.fragrance_pct_id"
                    :options="dropdowns.fragrance_pct"
                    size="sm"
                    label="text"
                    :reduce="option => option.value"
                    class="w-1/3"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading"
              @click="resetFilters"
            >
              Reset
            </b-btn>
          </div>
          <div>
            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const DateRange = () => import('@/components/invoices/DateRange.vue');

export default {
  name: 'FilterModal',
  components: {
    ButtonSelect,
    DateRange,
  },
  props: {
    data: Object,
    filters: Object,
    defaultFilters: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      return true;
    },
    dropdowns() {
      return this.$store.state.app.dropdowns;
    },
  },
  data() {
    return {
      loadingCount: 0,
      options: {
        candle_status: [
          { text: 'All', value: null },
          { text: 'Discarded', value: 'discarded', activeVariant: 'danger' },
          { text: 'Testing', value: 'testing', activeVariant: 'warning' },
          { text: 'Production', value: 'production', activeVariant: 'success' },
        ],
        is_favorite: [
          { text: 'All', value: null },
          { text: 'Yes', value: 1 },
          { text: 'No', value: 0 },
        ],
      },
    };
  },
  methods: {
    applyFilters() {
      this.filters.page = 1;
      this.$emit('input', this.filters);
    },
    fetchAll() {
      // no-op
    },
    resetFilters() {
      Object.keys(this.defaultFilters).forEach((key) => {
        this.filters[key] = this.defaultFilters[key];
      });
    },
    onShow() {},
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
  created() {
    this.fetchAll();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        if (!this.data.showModal) {
          return;
        }
        this.applyFilters();
      },
    },
  },
};
</script>

<style lang="scss">
.table-filter tbody tr td:nth-child(2) > div:first-child {
  display: flex;
  justify-content: flex-end;
}
</style>
